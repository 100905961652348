<template>
    <div class="check-in">
        <h3>{{$t("checkInTitle")}}</h3>
        <form class="check-in-form" v-on:submit.prevent="toPaymentMethod">
            <input type="email" class="form-control custom-input" id="email" v-model="customerEmail" name="" required :placeholder="$t('emailLabel')" autofocus>
            <button type="submit" class="btn btn-active" :disabled="customerEmail === null || customerEmail === ''">{{$t('confirm')}}</button>
        </form>
    </div>
</template>

<script>
    var basket = require('@/assets/js/Food/Basket');
    var Utils = require("@/assets/js/Utils");
    var Customer = require("@/assets/js/Customer");
    export default {
        data() {
            return {
                customerEmail: localStorage.getItem("DKC_customerEmail")
            }
        },
        methods: {
            toPaymentMethod(){

                basket.updateBasket(newBasket => {
                    localStorage.setItem("IEC_basket", JSON.stringify(newBasket));
                    this.$emit('basketChgt');
                    if(this.customerEmail != localStorage.getItem("DKC_customerEmail")){
                        Customer.updateCustomerEmail(this.customerEmail, (error) => {
                            if(!error){
                                localStorage.setItem("DKC_customerEmail", this.customerEmail);
                                this.$router.push('/paymentMethods');
                            }
                        });
                    }else{
                        this.$router.push('/paymentMethods');
                    }
                })
            }
        },
    }
</script>