import {getConfig, isNotNull, sendMails} from "../Utils";
const axios = require('axios');
import {BASKET_URL, PWA_SHORT_URL} from "@/assets/js/constantUtils";

var app = getConfig();

var basket = {
    basketProducts: [],
    lockedProducts: [],
    totalAmount: 0,
}; 

var fullBasket = [];

function initBasket(){
    if(JSON.parse(localStorage.getItem("IEC_basket"))){
        basket = JSON.parse(localStorage.getItem("IEC_basket"));
    }
}

export function getBasket() {
    initBasket();
    return basket;
}


export function showAlertDelete() {
    app.appContext.showAlert(app.appContext.$t('deleteFoodAlert'),"success");
}

export function addProductToLocalBasket(productToAdd){
    var added = false;
    var productEan = productToAdd.ean.split("_")[0];
    initBasket();
    let deletedProducts = parseInt(localStorage.getItem("IEC_PRODUCTS_DELETED"))
    if(basket.basketProducts.length == 0 && !deletedProducts){
        localStorage.setItem("IEC_START_DATE", new Date())
    }
    let searchProduct = basket.basketProducts.filter(cartProduct => cartProduct.product.id == productToAdd.id)
    if(searchProduct.length > 0){
        searchProduct[0].qty += 1
    }else{
        var tmpProduct = {};
        tmpProduct.id = Math.floor(Math.random() * Math.pow(10, 4));
        tmpProduct.qty = 1;
        tmpProduct.qtyLocked = false;
    
        if(productToAdd.ean.indexOf("_") > -1){
            tmpProduct.qtyLocked = true;
            basket.lockedProducts.push(productToAdd.ean);
            productToAdd.ean = productEan;
        }
        tmpProduct.product = productToAdd;
        basket.basketProducts.push(tmpProduct);
    }
    if(searchProduct.length > 0){
        if(searchProduct[0].product.ean === '5449000214911'){
            if(searchProduct[0].qty %2 == 0){
                basket.totalAmount += ((productToAdd.price)/2);
            }else{
                basket.totalAmount += productToAdd.price;
            }
        }else{
            basket.totalAmount += productToAdd.price;
        }
    }else{
        basket.totalAmount += productToAdd.price;
    }
    localStorage.setItem("IEC_basket", JSON.stringify(basket));
}

export function decreaseProductFromLocalBasket(productToDecrease) {
    initBasket();
    basket.basketProducts.some(function(element, index){
        if (element.product.id == productToDecrease.id && !element.qtyLocked) {
            if (element.qty > 1) {
                element.qty -= 1;
            } else {
                basket.basketProducts.splice(index, 1);
            }
            if(element.product.ean === '5449000214911'){
                if(element.qty %2 == 0 || !basket.basketProducts.includes(element)){
                    basket.totalAmount -= productToDecrease.price;
                }else{
                    basket.totalAmount -= productToDecrease.price / 2;
                }
            }else{
                basket.totalAmount -= productToDecrease.price;
            }  
            return true;
        }
    })
    let nbDeleted = parseInt(localStorage.getItem("IEC_PRODUCTS_DELETED")) || 0
    localStorage.setItem("IEC_PRODUCTS_DELETED", nbDeleted + 1);
    localStorage.setItem("IEC_basket", JSON.stringify(basket));
}

export function deleteProductFromLocalBasket(productToDelete) {
    initBasket();
    basket.basketProducts.forEach(function(element, index) {
        if (element.id == productToDelete.id) {
            basket.basketProducts.splice(index, 1);
            if(element.qtyLocked){
                var tmpLockedProducts = [];
                basket.lockedProducts.forEach(function(lockedProductEan) {
                    if(lockedProductEan.split("_")[0] != element.product.ean){
                        tmpLockedProducts.push(lockedProductEan);
                    }
                });
                basket.lockedProducts = tmpLockedProducts;
            }
            if(element.product.ean === '5449000214911'){
                let nbProductPromos = parseInt(productToDelete.qty / 2)
                let nbProductNormalPrice = productToDelete.qty - nbProductPromos
                basket.totalAmount -= productToDelete.product.price / 2 * nbProductPromos
                basket.totalAmount -= productToDelete.product.price * nbProductNormalPrice
            }else{
                basket.totalAmount -= productToDelete.product.price * productToDelete.qty;
            }
        }
    })
    let nbDeleted = parseInt(localStorage.getItem("IEC_PRODUCTS_DELETED")) || 0
    localStorage.setItem("IEC_PRODUCTS_DELETED", nbDeleted + 1);
    localStorage.setItem("IEC_basket", JSON.stringify(basket));
}

export function emptyLocalBasket() {
    initBasket();
    basket.basketProducts = [];
    basket.lockedProducts = [];
    basket.totalAmount = 0;
    localStorage.setItem("IEC_basket", JSON.stringify(basket));
    app.appContext.basketChange();
}


function formatBasketBeforeUpdate() {
    let localBasket = JSON.parse(localStorage.getItem('IEC_basket'));
    let formatedBasket = {
        productsCart: []
    };
    localBasket.basketProducts.forEach(function(product) {
        formatedBasket.productsCart.push({
            productId: product.product.id,
            qty: product.qty
        });
    })

    return formatedBasket;
}

export function getLocalBasket(){
    return JSON.parse(localStorage.getItem("IEC_basket"));
}

export function updateBasket(callback) {
    
    const formatedBasket = formatBasketBeforeUpdate();

    var postdata = new URLSearchParams();
    postdata.append("token", localStorage.getItem("IEC_TOKEN"));
    postdata.append("products", JSON.stringify(formatedBasket));
    axios.post(BASKET_URL.MANAGE_PRODUCTS(), postdata)
        .then(response => {
            if(response.status == 200){
                getCustomerBasket(callback)
            }
        })
}

export function validBasket(callback) {
    var postdata = new URLSearchParams();
    postdata.append("token", localStorage.getItem("IEC_TOKEN"));
    postdata.append("orderOrigin", "IEC_SCAN_AND_PAY");
    let suspectBasket = isSuspect()
    if(Object.keys(suspectBasket).length > 0){
        postdata.append("isSuspect", JSON.stringify(suspectBasket));
    }else{
        postdata.append("isSuspect", "");
    }
    
    axios.post(BASKET_URL.VALID_BASKET(), postdata)
        .then(response => {
            if(response.status == 200){
                localStorage.removeItem("IEC_PRODUCTS_DELETED")
                localStorage.removeItem("IEC_START_DATE")
                localStorage.removeItem("selectedSlot")
                localStorage.removeItem("IEC_LOYALTY_TO_USE")
                localStorage.removeItem("IEC_loyaltyCard")
                let data = response.data;
                localStorage.setItem("IEC_encodedTicketId", data.complexId);
                getCustomerBasket(callback);
                let ticketUrl = "";
                if(process.env.NODE_ENV === 'production'){
                    ticketUrl = PWA_SHORT_URL + "?tId=" + data.complexId ;
                }else{
                    ticketUrl = "http://localhost:8080/index" + "?tId=" + data.complexId ;
                }

                let loadedDemo = JSON.parse(sessionStorage.getItem("IEC_loadedDemo"))
                if(loadedDemo != null){
                    ticketUrl += '&d=' + loadedDemo.id
                }
                console.log(ticketUrl)
                
                let email = localStorage.getItem("email");
                let tel = JSON.parse(localStorage.getItem("phoneNumber"));
                let basket = JSON.parse(localStorage.getItem('IEC_basket'))
                
                if(tel != null && tel != ""){
                    let recipient = tel.formattedNumber.replace("+", "00")
                    let smsMessage = basket.orderNumber ? "ScanPay's,\nVeuillez trouver votre e-ticket pour votre commande #"+ basket.orderNumber.toString().slice(-2) +" :\n" : "ScanPay's,\nVeuillez trouver votre e-ticket pour votre commande:\n"
                    sendSMS(recipient, encodeURIComponent(smsMessage + ticketUrl));
                }

                if(email!=null && email != ""){
                    sendMail(email, encodeURIComponent(ticketUrl));
                }
            }
        })
}

export function setSuspect(callback) {
    var path = "/basket/set/suspect";
    var url = app.DKW_URL + path;
    var request = new XMLHttpRequest();
    var params = "token=" + localStorage.getItem("IEC_TOKEN");
    request.onreadystatechange = function() {
        if (request.readyState === XMLHttpRequest.DONE) {
            if (request.status === 200) {
                var response = JSON.parse(request.response);
                if(response.error == false){
                    getCustomerBasket(callback);
                }else{
                    app.appContext.showAlert(app.appContext.$t('errorValidBasket'),"error");
                }
            }else{
                app.appContext.showAlert(app.appContext.$t('errorValidBasket'),"error");
            }
        }
    };
    request.open('POST', url, false);
    request.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');

    request.send(params);
}

export function getCustomerBasket(callback) {
    let token = localStorage.getItem("IEC_TOKEN");
    fullBasket = {
        id: 0,
        totalAmount: 0,
        basketProducts: [],
        lockedProducts:[]
    };
    if(token){
        axios.get(BASKET_URL.GET_BASKET_BY_STATE().format({token, state: 'IN_PROGRESS'}))
        .then(response => {
            if(response.status == 200){
                let basketsList = response.data;
                basketsList.forEach(function(customerBasket) {
                    if (customerBasket.state == "IN_PROGRESS") {
                        fullBasket.complexId = customerBasket.complexId;
                        fullBasket.id = customerBasket.id;
                        fullBasket.totalAmount = customerBasket.totalAmount;
                        fullBasket.basketProducts = customerBasket.basketProducts;
                        fullBasket.orderNumber = customerBasket.orderNumber;
                        fullBasket.state = customerBasket.state;
                    }
                });
                if(callback != null){
                    callback(fullBasket)
                }
            }
        })
    }else{
        callback(fullBasket);
    }
}

export function getTicket(encodedTicketId, callback) {
    var path = "/basket/getBasketByComplexId";
    var url = app.DKW_URL + path;
    var request = new XMLHttpRequest();
    var params = "?complexId=" + encodedTicketId;
    request.onreadystatechange = function() {
        if (request.readyState === XMLHttpRequest.DONE) {
            if (request.status === 200) {
                var response = JSON.parse(request.response)
                callback(response);
            }else{
                app.appContext.showAlert(app.appContext.$t('errorGetTicket'),"error");
            }
        }
    };
    request.open('GET', url + params, true);
    request.send();
}

export function getKeyword(lang, callback) {
    var path = "/basket/get/keyword";
    var url = app.DKW_URL + path;
    var params = "?lang=" + lang;
    var request = new XMLHttpRequest();
    request.onreadystatechange = function() {
        if (request.readyState === XMLHttpRequest.DONE) {
            if (request.status === 200) {
                var response = request.response
                callback(response);
            }else{
                app.appContext.showAlert(app.appContext.$t('errorGetTicket'),"error");
            }
        }
    };
    request.open('GET', url + params, true);
    request.send();
}

export function clearBasket() {
    if(isNotNull(localStorage.getItem("IEC_TOKEN"))){
        app = getConfig();
        var path = "/basket/clear";
        var url = app.DKW_URL + path;
        var request = new XMLHttpRequest();
        var params = "token=" + localStorage.getItem("IEC_TOKEN");
        request.onreadystatechange = function() {
            if (request.readyState === XMLHttpRequest.DONE) {
                if (request.status === 200) {
                    var response = JSON.parse(request.response);
                    if(response.error == false){
                        emptyLocalBasket();
                    }else{
                        app.appContext.showAlert(app.appContext.$t('errorClearBasket'),"error");
                    }
                }else{
                    app.appContext.showAlert(app.appContext.$t('errorClearBasket'),"error");
                }
            }
        };
        request.open('POST', url, true);
        request.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');

        request.send(params);
    }
}

export function getProductQuantity(productToSearch){
    let localBasket = getLocalBasket()
    if(localBasket != null){
        let foundProduct = localBasket.basketProducts.filter(({product}) => product.id == productToSearch.id)
        return foundProduct[0] ? foundProduct[0].qty : 0
    }
    return 0
}

export function initLanePayment(terminalId, orderId, callback){
    let postdata = new URLSearchParams();
    postdata.append("token", localStorage.getItem("IEC_TOKEN"));
    postdata.append("terminalId", terminalId);
    postdata.append("orderId", orderId);
    
    axios.post(BASKET_URL.INIT_LANE_PAYMENT(), postdata)
        .then(res => callback(res.data))
        .catch(res => callback(null))
}

export function checkLanePayment(transactionId){
    let postdata = new URLSearchParams();
    postdata.append("token", localStorage.getItem("IEC_TOKEN"));
    postdata.append("transactionId", transactionId);
    return axios.post(BASKET_URL.CHECK_LANE_PAYMENT(), postdata)
}
export function getCustomerBasketsByState(state){
    let token = localStorage.getItem("IEC_TOKEN");
    if(token){
        return axios.get(BASKET_URL.GET_BASKET_BY_STATE().format({token, state}))
    }
    return null
}
export function setBasketToSuspect(){
    let token = localStorage.getItem("IEC_TOKEN");
    if(token){
        return axios.post(BASKET_URL.SET_SUPECT_BASKET()).format({token})
    }
}
export function getSuspectConfig(){
    let keys = {
        "configurationsKeys": [
            { "configurationKey":"suspectBasketNbProduct" },
            { "configurationKey":"suspectBasketNbProductDeleted" },
            { "configurationKey":"suspectBasketTimeBetweenOrderAndPayment" },
            { "configurationKey":"suspectBasketExpansiveProduct" },
        ]
    }
    return axios.get(BASKET_URL.GET_SUSPECT_CONFIG().format({keys: encodeURIComponent(JSON.stringify(keys))}))
}
export function getBasketByComplexId(complexId, callback) {
    axios.get(BASKET_URL.GET_BASKET_BY_COMPLEX_ID().format({complexId}))
        .then(response => {
            if(response.status == 200){
                let data = response.data;
                if(!data.error){
                    callback(data);
                }
            }
        })
}

function isSuspect(){
    let basket = JSON.parse(localStorage.getItem('IEC_basket'))
    let suspectBasketConfig = JSON.parse(localStorage.getItem("IEC_SUPSECT_BASKET_CONFIG"))

    let maxBasketProducts = suspectBasketConfig.filter(conf => conf.configurationKey == "suspectBasketNbProduct")
    let suspectBasketNbProductDeleted = suspectBasketConfig.filter(conf => conf.configurationKey == "suspectBasketNbProductDeleted")
    let suspectBasketExpensiveProduct = suspectBasketConfig.filter(conf => conf.configurationKey == "suspectBasketExpansiveProduct")
    let suspectBasketTimeBetweenOrderAndPayment = suspectBasketConfig.filter(conf => conf.configurationKey == "suspectBasketTimeBetweenOrderAndPayment")

    let suspectInfos = {}

    if(maxBasketProducts && maxBasketProducts.length > 0){
        let totalNb = basket.basketProducts.reduce((acc, product) => acc + product.qty, 0)
        if(totalNb > maxBasketProducts[0].configurationValue){
            suspectInfos["NBR_PRODUCTS"] = {
                value: totalNb,
                configuredValue: maxBasketProducts[0].configurationValue
            }
        }
    }
    if(suspectBasketNbProductDeleted && suspectBasketNbProductDeleted.length > 0){
        let nbProductsDeleted = parseInt(localStorage.getItem("IEC_PRODUCTS_DELETED"))
        if(nbProductsDeleted > suspectBasketNbProductDeleted[0].configurationValue){
            suspectInfos["PRODUCTS_DELETED"] = {
                value: nbProductsDeleted,
                configuredValue: suspectBasketNbProductDeleted[0].configurationValue
            }
        }
    }
    if(suspectBasketExpensiveProduct && suspectBasketExpensiveProduct.length > 0 ){
        basket.basketProducts.forEach(basketProduct => {
            if(basketProduct.product.price >= suspectBasketExpensiveProduct[0].configurationValue){
                suspectInfos["EXPENSIVE_PRODUCT"] = {
                    value: basketProduct.product.price,
                    configuredValue: suspectBasketExpensiveProduct[0].configurationValue
                }
            }
        })
    }
    if(suspectBasketTimeBetweenOrderAndPayment && suspectBasketTimeBetweenOrderAndPayment.length > 0){
        let startDate = new Date(localStorage.getItem("IEC_START_DATE")) || null
        let currentDate = new Date()
        let orderDuration = (currentDate - startDate)/(60*1000)
        if(orderDuration > suspectBasketTimeBetweenOrderAndPayment[0].configurationValue){
            suspectInfos["PURCHASE_DURATION"] = {
                value: orderDuration,
                configuredValue: suspectBasketTimeBetweenOrderAndPayment[0].configurationValue
            }
        }
    }
    return suspectInfos
}
